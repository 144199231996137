<template>
  <div class="filters">
    <p>
      <strong>CREATORS:</strong>&nbsp;
      <span>
        <RouterLink
          :class="($route.query.by || $route.query.order === 'r') ? '' : 'is-active'"
          :to="{ path: '/artworks' }"
        >
          All
        </RouterLink>
      </span>
      <span
        v-for="(creator, i) in creators"
        :key="creator"
      >
        &middot;
        <RouterLink
          :class="$route.query.by === creator.uri ? 'is-active' : ''"
          :to="{ path: '/artworks', query: { by: creator.uri } }"
        >
          {{ creator.name }}
        </RouterLink>
      </span>
      <br>
      <strong>ORDER:</strong>&nbsp;
      <span>
        <RouterLink
          :class="($route?.query?.order === 'r') ? '' : 'is-active'"
          :to="{ path: '/artworks' }"
        >
          Availability
        </RouterLink>
      </span>
      <span>
        &middot;
        <RouterLink
          :class="$route.query.order === 'r' ? 'is-active' : ''"
          :to="{ path: '/artworks', query: { order: 'r' } }"
        >
          Reverse
        </RouterLink>
      </span>
    </p>
  </div>
  <Grid
    class="grid--artworks"
    :items="artworks"
    v-slot="slotProps"
  >
    <GridItem :item="slotProps.item" :type="slotProps.item.type" :key="slotProps.item" />
  </Grid>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue"
import { featuredInteractiveMany } from "../constants.js"
import { loadArtworks, loadMedias, loadAuctions } from "../services.js"
import Grid from "./Grid.vue"
import GridItem from "./GridItem.vue"

export default defineComponent({
  name: "Artworks",
  components: {
    Grid,
    GridItem,
  },
  setup() {
    const items = ref([])

    onMounted(async () => {
      const artworks = await loadArtworks()
      const getZoraMediaId = artwork => artwork.zoraMediaId
      const mediaIdMany = artworks.filter(getZoraMediaId).map(getZoraMediaId)

      // load auctions
      const auctionMany = await loadAuctions(mediaIdMany)

      // check media with no auctions
      const noAuctionMediaIdMany = mediaIdMany.reduce((prev, next) => {
        if (auctionMany.some(({ media }) => media.id === next)) {
          return [...prev]
        }

        return [...prev, next]
      }, [])

      // load media
      const mediaMany = await loadMedias(noAuctionMediaIdMany)

      // map auctions/media to artworks and define type
      items.value = artworks
        .map((artwork) => {
          if (artwork.zoraMediaId) {
            // check for auction
            const media = mediaMany.find(media => media.id === artwork.zoraMediaId)
            const auction = auctionMany.find(auction => auction.media.id === artwork.zoraMediaId)
            const type = auction ? "auction" : "media"

            return { ...artwork, auction, media, type }
          }

          return artwork
        })
        // sort featured - media.id with no media being first
        .sort((a, b) => (b?.media?.id > a?.media?.id || a?.media) ? 1 : -1)
    })

    return {
      items,
    }
  },
  computed: {
    artworks() {
      const s = this.$route.query.s

      if (s) {
        return this.items.filter(item => item?.description.includes(s))
      }

      const by = this.$route.query.by

      if (by) {
        return this.items.filter(item => item?.artist?.uri.includes(by))
      }

      const order = this.$route.query.order

      if (order === "r") {
        return [...this.items].reverse()
      }

      return this.items
    },
    creators() {
      return this.items
        .filter(item => item?.artist)
        .reduce((prev, next) => {
          if (prev.some(({ artist }) => artist.name === next?.artist?.name)) {
            return prev
          }

          return [...prev, next]
        }, [])
        .map(item => item.artist)
        .sort((a, b) => a?.uri > b?.uri ? 1 : -1)
    }
  },
})
</script>

<style>
.filters {
  padding: 2rem 2rem 0;
}
.is-active {
  text-decoration: none;
}
</style>
